/**
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import InlineResponse2008 from '../model/InlineResponse2008';
import ShuttleOrderJsonldShuttleOrderRead from '../model/ShuttleOrderJsonldShuttleOrderRead';
import ShuttleOrderJsonldShuttleOrderWrite from '../model/ShuttleOrderJsonldShuttleOrderWrite';

/**
* ShuttleOrder service.
* @module api/ShuttleOrderApi
* @version 1.0.0
*/
export default class ShuttleOrderApi {

    /**
    * Constructs a new ShuttleOrderApi. 
    * @alias module:api/ShuttleOrderApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiShuttleOrdersGetCollection operation.
     * @callback module:api/ShuttleOrderApi~apiShuttleOrdersGetCollectionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2008} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves the collection of ShuttleOrder resources.
     * Retrieves the collection of ShuttleOrder resources.
     * @param {Object} opts Optional parameters
     * @param {module:api/ShuttleOrderApi~apiShuttleOrdersGetCollectionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2008}
     */
    apiShuttleOrdersGetCollection(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/ld+json'];
      let returnType = InlineResponse2008;

      return this.apiClient.callApi(
        '/api/shuttle_orders', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiShuttleOrdersIdDelete operation.
     * @callback module:api/ShuttleOrderApi~apiShuttleOrdersIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Removes the ShuttleOrder resource.
     * Removes the ShuttleOrder resource.
     * @param {module:api/ShuttleOrderApi~apiShuttleOrdersIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiShuttleOrdersIdDelete(id, callback) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/shuttle_orders/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiShuttleOrdersIdGet operation.
     * @callback module:api/ShuttleOrderApi~apiShuttleOrdersIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ShuttleOrderJsonldShuttleOrderRead} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves a ShuttleOrder resource.
     * Retrieves a ShuttleOrder resource.
     * @param {module:api/ShuttleOrderApi~apiShuttleOrdersIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ShuttleOrderJsonldShuttleOrderRead}
     */
    apiShuttleOrdersIdGet(id, callback) {
      let postBody = null;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/ld+json'];
      let returnType = ShuttleOrderJsonldShuttleOrderRead;

      return this.apiClient.callApi(
        '/api/shuttle_orders/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiShuttleOrdersIdPut operation.
     * @callback module:api/ShuttleOrderApi~apiShuttleOrdersIdPutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ShuttleOrderJsonldShuttleOrderRead} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Replaces the ShuttleOrder resource.
     * Replaces the ShuttleOrder resource.
     * @param {module:api/ShuttleOrderApi~apiShuttleOrdersIdPutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ShuttleOrderJsonldShuttleOrderRead}
     */
    apiShuttleOrdersIdPut(body, id, callback) {
      let postBody = body;

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/ld+json'];
      let accepts = ['application/ld+json'];
      let returnType = ShuttleOrderJsonldShuttleOrderRead;

      return this.apiClient.callApi(
        '/api/shuttle_orders/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiShuttleOrdersPost operation.
     * @callback module:api/ShuttleOrderApi~apiShuttleOrdersPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ShuttleOrderJsonldShuttleOrderRead} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Creates a ShuttleOrder resource.
     * Creates a ShuttleOrder resource.
     * @param {module:api/ShuttleOrderApi~apiShuttleOrdersPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ShuttleOrderJsonldShuttleOrderRead}
     */
    apiShuttleOrdersPost(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/ld+json'];
      let accepts = ['application/ld+json'];
      let returnType = ShuttleOrderJsonldShuttleOrderRead;

      return this.apiClient.callApi(
        '/api/shuttle_orders', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
