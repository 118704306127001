import React, { useEffect, useState } from "react";
import { lightenColor } from "utils/utils";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { comment } from "postcss";

function Category({ category, blanksNr, onItemsChange, onCommentChange }) {
  const [items, setItems] = useState(
    category.items.length ? category.items : [{ quantity: 1, name: "", kind: "" }]
  );
  const [comment, setComment] = useState(category.comment ? category.comment.toString() : "");
  const [tooltips, setTooltips] = useState({ name: "", kind: "" });
  const [blanks, setBlanks] = useState(blanksNr);
  const categoryColor = category.colorHex;
  const lighterColor = lightenColor(categoryColor, 20);
  const categoryGradient = `linear-gradient(70deg, ${categoryColor} 20%, ${lighterColor} 80%)`;

  const handleItemChange = (index, key, value) => {
    setItems((prevItems) =>
      prevItems.map((item, i) => (i === index ? { ...item, [key]: value } : item))
    );
  };

  useEffect(() => {
    let blanksNr;

    if (!category.items.length) {
      switch (category.name) {
        case "Bread & Cereals":
        case "Fruits & Vegetables":
          blanksNr = 2;
          break;
        case "Additional Food and Beverage Items":
          blanksNr = 5;
          break;
        default:
          blanksNr = 1;
      }
    }

    setBlanks(blanksNr);
  }, [category, blanksNr]);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  // Call these functions when the input field loses focus
  const handleItemBlur = () => {
    onItemsChange(items);
  };

  const handleCommentBlur = () => {
    onCommentChange(comment);
  };

  const handleAddItem = () => {
    const newItems = [...items, { quantity: 1, name: "", kind: "" }];
    setItems(newItems);
    onItemsChange(newItems);
  };

  const handleDeleteItem = (index) => {
    if (items.length > 1) {
      const newItems = items.filter((_, i) => i !== index);
      setItems(newItems);
      onItemsChange(newItems);
    }
  };

  useEffect(() => {
    let blanksNr;

    if (!category.items.length) {
      switch (category.name) {
        case "Bread & Cereals":
        case "Fruits & Vegetables":
          blanksNr = 2;
          break;
        case "Additional Food and Beverage Items":
          blanksNr = 5;
          break;
        default:
          blanksNr = 1;
      }
    }

    const blankItems = Array(blanksNr).fill({ quantity: 1, name: "", kind: "" });
    setItems(category.items.length ? category.items : blankItems);
    setComment(category.comment ? category.comment.toString() : "");
  }, [category]);

  return (
    <Box py={2}>
      <Card variant="outlined">
        <CardContent>
          <MDBox
            my={2}
            p={2}
            sx={{
              background: categoryGradient,
              borderRadius: "0.75rem",
            }}
          >
            <h2 style={{ margin: 0, lineHeight: 1, color: "white" }}>{category.name}</h2>
          </MDBox>
          {items.map((item, index) => (
            <Box key={index}>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12} md={1}>
                  {item.name.toLowerCase().includes("water") &&
                  item.kind.toLowerCase().includes("tap") ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      &#8734;
                    </div>
                  ) : (
                    <TextField
                      value={item.quantity}
                      label="Quantity"
                      type="number"
                      fullWidth
                      onChange={(e) =>
                        handleItemChange(index, "quantity", parseInt(e.target.value))
                      }
                      onBlur={(e) => handleItemBlur()}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    value={item.name}
                    label={"Name" + (tooltips && tooltips.name ? ` (${tooltips.name})` : "")}
                    fullWidth
                    onChange={(e) => handleItemChange(index, "name", e.target.value)}
                    onBlur={(e) => handleItemBlur()}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  {items.length > 1 && (
                    <Button onClick={() => handleDeleteItem(index)}>Delete</Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
          <Button onClick={(e) => handleAddItem(e)}>Add Item</Button>
          <TextField
            onChange={(e) => handleCommentChange(e)}
            onBlur={(e) => handleCommentBlur()}
            value={comment}
            label={`Comment on ${category.name}`}
            fullWidth
          />
        </CardContent>
      </Card>
    </Box>
  );
}

Category.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        name: PropTypes.string,
        kind: PropTypes.string,
      })
    ),
    comment: PropTypes.string,
    colorHex: PropTypes.string,
  }),
  blanksNr: PropTypes.number,
  onItemsChange: PropTypes.func,
  onCommentChange: PropTypes.func,
};

export default Category;
