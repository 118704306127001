/**
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import InlineResponse2001Hydraview from './InlineResponse2001Hydraview';
import InlineResponse200Hydrasearch from './InlineResponse200Hydrasearch';

/**
* The InlineResponse2008 model module.
* @module model/InlineResponse2008
* @version 1.0.0
*/
export default class InlineResponse2008 {
    /**
    * Constructs a new <code>InlineResponse2008</code>.
    * @alias module:model/InlineResponse2008
    * @class
    * @param hydramember {Object} 
    */

    constructor(hydramember) {
        
        
        this['hydra:member'] = hydramember;
        
    }

    /**
    * Constructs a <code>InlineResponse2008</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/InlineResponse2008} obj Optional instance to populate.
    * @return {module:model/InlineResponse2008} The populated <code>InlineResponse2008</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InlineResponse2008();
                        
            
            if (data.hasOwnProperty('hydra:member')) {
                obj['hydra:member'] = ApiClient.convertToType(data['hydra:member'], Object);
            }
            if (data.hasOwnProperty('hydra:totalItems')) {
                obj['hydra:totalItems'] = ApiClient.convertToType(data['hydra:totalItems'], Object);
            }
            if (data.hasOwnProperty('hydra:view')) {
                obj['hydra:view'] = InlineResponse2001Hydraview.constructFromObject(data['hydra:view']);
            }
            if (data.hasOwnProperty('hydra:search')) {
                obj['hydra:search'] = InlineResponse200Hydrasearch.constructFromObject(data['hydra:search']);
            }
        }
        return obj;
    }

    /**
    * @member {Object} hydra:member
    */
    'hydra:member' = undefined;
    /**
    * @member {Object} hydra:totalItems
    */
    'hydra:totalItems' = undefined;
    /**
    * @member {module:model/InlineResponse2001Hydraview} hydra:view
    */
    'hydra:view' = undefined;
    /**
    * @member {module:model/InlineResponse200Hydrasearch} hydra:search
    */
    'hydra:search' = undefined;




}
