import { CompanyApi } from "api/src";
import ConciergeLayout from "components/concierge/components/ConciergeLayout";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import bgImage from "assets/images/shuttle/davos.jpg";
import mapImage from "assets/images/shuttle/square.png";
import headerImage from "assets/images/shuttle/header.jpg";

// Material-UI imports
import { Card, CardContent, CardMedia, Checkbox, Divider } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import GetAppIcon from "@mui/icons-material/GetApp";

// API imports
import { AccommodationApi } from "api/src/api/AccommodationApi"; // adjust the import according to your project structure
import { TemplateCategoryApi } from "api/src";
import { OrderApi } from "api/src";

// Component imports
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ShuttleMapComponent from "./components/ShuttleMapComponent";
import LocationAutocomplete from "./components/LocationAutocomplete";
import { LoadScript } from "@react-google-maps/api";

import locationsFromFile from "./components/addresses.json";
import { ShuttleOrderApi } from "api/src";
import { Link } from "react-router-dom";

const libraries = ["places"];

const Shuttle = () => {
  const { companyCode } = useParams();
  const [company, setCompany] = useState([]);
  const [shuttleOrder, setShuttleOrder] = useState({
    passengerFirstName: "",
    passengerLastName: "",
    passengerEmail: "",
    numberOfPassengers: 1,
    pickUpDate: "",
    pickUpTime: "",
    pickUpPlace: null,
    dropOffPlace: null,
    flightNumber: "",
    estimatedTimeOfArrival: null,
    specialNotes: "",
    company: {},
  });
  const [shuttleOrderResponse, setShuttleOrderResponse] = useState(null);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [dropoffAddress, setDropoffAddress] = useState(null);
  const [pickupError, setPickupError] = useState(false);
  const [dropoffError, setDropoffError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [locations, setLocations] = useState(locationsFromFile);

  // Create a ref for the top element
  const topElementRef = useRef(null);

  const companyApi = new CompanyApi();
  const shuttleOrderApi = new ShuttleOrderApi();

  const handleDownload = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;

    // Extract filename from the URL
    const urlObj = new URL(url);
    const filename = urlObj.pathname.split("/").pop();

    link.download = filename;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleSubmit = () => {
    let orderToSubmit = {
      ...shuttleOrder,
      company: company["@id"],
    };

    console.log(orderToSubmit);

    // Check if pickup address is empty or null
    if (!shuttleOrder.pickUpPlace || shuttleOrder.pickUpPlace === "") {
      setPickupError(true);
    } else {
      setPickupError(false);
    }

    // Check if dropoff address is empty or null
    if (!shuttleOrder.dropOffPlace || shuttleOrder.dropOffPlace === "") {
      setDropoffError(true);
    } else {
      setDropoffError(false);
    }

    if (pickupError || dropoffError) {
      return;
    }

    ["pickUpPlace", "dropOffPlace"].forEach((key) => {
      if (orderToSubmit[key]) {
        Object.keys(orderToSubmit[key]).forEach((subKey) => {
          // If the value is null or 'undefined', replace it with an empty string
          if (orderToSubmit[key][subKey] === null || orderToSubmit[key][subKey] === "undefined") {
            orderToSubmit[key][subKey] = "";
          }
          // If subKey is 'street' and its value contains 'undefined', replace 'undefined' with an empty string
          else if (subKey === "street" && orderToSubmit[key][subKey].includes("undefined")) {
            orderToSubmit[key][subKey] = "";
          }
          // Check if subKey is 'zipCode' and its value is a number
          else if (subKey === "zipCode" && typeof orderToSubmit[key][subKey] === "number") {
            // Convert the zipCode to a string
            orderToSubmit[key][subKey] = orderToSubmit[key][subKey].toString();
          }
        });
        // If description contains the country name or street, remove it and any leading or trailing commas and whitespaces
        if (orderToSubmit[key].description) {
          let fieldsToRemove = [orderToSubmit[key].country, orderToSubmit[key].street];
          fieldsToRemove.forEach((field) => {
            if (field) {
              let regex = new RegExp(`,?\\s*${field}\\s*,?`, "g");
              orderToSubmit[key].description = orderToSubmit[key].description.replace(regex, "");
            }
          });
        }

        // If description is set but name is not, and both street and zip code are not set, make the description the name
        if (
          orderToSubmit[key].description &&
          !orderToSubmit[key].name &&
          !orderToSubmit[key].street &&
          !orderToSubmit[key].zipCode
        ) {
          orderToSubmit[key].name = orderToSubmit[key].description;
        }
      }
    });

    // Parse numberOfPassengers to an integer
    if (orderToSubmit.numberOfPassengers) {
      orderToSubmit.numberOfPassengers = parseInt(orderToSubmit.numberOfPassengers, 10);
    }

    shuttleOrderApi.apiShuttleOrdersPost(orderToSubmit, (error, data, response) => {
      if (error) {
        console.error("An error occurred while submitting the shuttle order:", error);
      } else {
        setShuttleOrderResponse(data);
        topElementRef.current.scrollIntoView({ behavior: "smooth" });
      }
    });
  };

  const onPickupChange = (selectedPickup) => {
    setPickupAddress(selectedPickup);
    setShuttleOrder({ ...shuttleOrder, pickUpPlace: selectedPickup });
  };

  const onDropoffChange = (selectedDropOff) => {
    setDropoffAddress(selectedDropOff);
    setShuttleOrder({ ...shuttleOrder, dropOffPlace: selectedDropOff });
  };

  // Fetch the company
  useEffect(() => {
    if (companyCode) {
      companyApi.apiCompaniesCodeGet(companyCode, (error, data, response) => {
        if (error) {
          console.error("An error occurred while fetching the company:", error);
        } else {
          setCompany(data);
          if (data.code != "sap") {
            setLocations([]);
          }
        }
      });
    }
  }, [companyCode]);

  if (!companyCode) {
    return <div>Please use the link provided to you.</div>;
  }

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
      <ConciergeLayout image={bgImage}>
        <form
          onSubmit={(e) => {
            e.preventDefault(); // Prevent the default form submission
            handleSubmit(); // Call your submit function
          }}
        >
          <MDBox py={2}>
            <Card sx={{ borderRadius: 1, boxShadow: 5 }} variant="outlined">
              <CardContent p={4}>
                <div ref={topElementRef}>
                  <CardMedia
                    component="img"
                    sx={{ width: "100%", marginLeft: 0, marginRight: 0, marginBottom: 4 }}
                    image={headerImage}
                    title="header"
                  />
                </div>
                {shuttleOrderResponse ? (
                  <Grid container justifyContent="center" alignItems="center" direction="column">
                    <MDTypography variant="h4" gutterBottom>
                      Order Confirmation
                    </MDTypography>
                    <MDTypography variant="body1" style={{ textAlign: "center" }} gutterBottom>
                      Thank you for your order! You can download the order confirmation below.{" "}
                      <br /> You will receive driver contact details as well as pick-up information
                      in time via WhatsApp (or Email if provided).
                    </MDTypography>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      style={{ maxWidth: "500px", marginBottom: "20px" }}
                    >
                      <Grid container justifyContent="space-between">
                        <Grid item xs={6}>
                          <MDTypography variant="body1" fontWeight="bold">
                            Full Name:
                          </MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="body1">{`${shuttleOrderResponse.passengerFirstName} ${shuttleOrderResponse.passengerLastName}`}</MDTypography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={6}>
                          <MDTypography variant="body1" fontWeight="bold">
                            Order Number:
                          </MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="body1">
                            {shuttleOrderResponse.orderNumber}
                          </MDTypography>
                        </Grid>
                      </Grid>
                      {/* ... */}
                      <Grid container justifyContent="space-between">
                        <Grid item xs={6}>
                          <MDTypography variant="body1" fontWeight="bold">
                            Pick-up Location:
                          </MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="body1">
                            {shuttleOrderResponse.pickUpPlace.name ? (
                              <>
                                {shuttleOrderResponse.pickUpPlace.name} <br />
                              </>
                            ) : null}
                            {[
                              shuttleOrderResponse.pickUpPlace.street,
                              shuttleOrderResponse.pickUpPlace.zipCode,
                              shuttleOrderResponse.pickUpPlace.city,
                              shuttleOrderResponse.pickUpPlace.country,
                            ]
                              .filter(Boolean)
                              .join(", ")}
                          </MDTypography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={6}>
                          <MDTypography variant="body1" fontWeight="bold">
                            Drop-off Location:
                          </MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="body1">
                            {shuttleOrderResponse.dropOffPlace.name ? (
                              <>
                                {shuttleOrderResponse.dropOffPlace.name} <br />
                              </>
                            ) : null}
                            {[
                              shuttleOrderResponse.dropOffPlace.street,
                              shuttleOrderResponse.dropOffPlace.zipCode,
                              shuttleOrderResponse.dropOffPlace.city,
                              shuttleOrderResponse.dropOffPlace.country,
                            ]
                              .filter(Boolean)
                              .join(", ")}
                          </MDTypography>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={6}>
                          <MDTypography variant="body1" fontWeight="bold">
                            Pick-up Date/Time:
                          </MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="body1">
                            {`${new Date(shuttleOrderResponse.pickUpDate).toLocaleDateString(
                              "en-GB",
                              { weekday: "short", day: "2-digit", month: "short", year: "numeric" }
                            )} - ${new Date(shuttleOrderResponse.pickUpTime).toLocaleTimeString(
                              [],
                              { hour: "2-digit", minute: "2-digit" }
                            )}`}
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <MDButton
                      variant="contained"
                      color="success"
                      startIcon={<GetAppIcon />}
                      onClick={() =>
                        handleDownload(
                          `${shuttleOrderResponse.orderConfirmationPdfUrl}?code=${shuttleOrderResponse.code}`
                        )
                      }
                    >
                      Download Order Confirmation
                    </MDButton>
                  </Grid>
                ) : (
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Box borderRadius={4} overflow="hidden" sx={{ height: "100%" }}>
                        <ShuttleMapComponent
                          onPickupChange={onPickupChange}
                          onDropoffChange={onDropoffChange}
                          locations={locations}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="First Name"
                                onChange={(e) =>
                                  setShuttleOrder({
                                    ...shuttleOrder,
                                    passengerFirstName: e.target.value,
                                  })
                                }
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="Last Name"
                                onChange={(e) =>
                                  setShuttleOrder({
                                    ...shuttleOrder,
                                    passengerLastName: e.target.value,
                                  })
                                }
                                fullWidth
                                required
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                label="E-Mail (optional)"
                                type="email"
                                onChange={(e) =>
                                  setShuttleOrder({
                                    ...shuttleOrder,
                                    passengerEmail: e.target.value,
                                  })
                                }
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={2}>
                              <TextField
                                label="PAX"
                                type="number"
                                defaultValue={1}
                                onChange={(e) =>
                                  setShuttleOrder({
                                    ...shuttleOrder,
                                    numberOfPassengers: e.target.value,
                                  })
                                }
                                fullWidth
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                label="Pick-Up Date"
                                type="date"
                                fullWidth
                                onChange={(e) =>
                                  setShuttleOrder({ ...shuttleOrder, pickUpDate: e.target.value })
                                }
                                InputLabelProps={{ shrink: true }}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="Pick-Up Time"
                                type="time"
                                fullWidth
                                onChange={(e) =>
                                  setShuttleOrder({ ...shuttleOrder, pickUpTime: e.target.value })
                                }
                                InputLabelProps={{ shrink: true }}
                                required
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="Flight/Train No."
                                fullWidth
                                onChange={(e) =>
                                  setShuttleOrder({ ...shuttleOrder, flightNumber: e.target.value })
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                label="Arrival/Departure Time Flight/Train"
                                type="time"
                                fullWidth
                                onChange={(e) =>
                                  setShuttleOrder({
                                    ...shuttleOrder,
                                    estimatedTimeOfArrival: e.target.value,
                                  })
                                }
                                InputLabelProps={{ shrink: true }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <MDTypography variant="body2">
                            Select Pick-up and Drop-off Location from map or search below.{" "}
                            Alternatively, you can freely search for or enter any address.
                          </MDTypography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <LocationAutocomplete
                                error={pickupError}
                                label="Pick-up Location *"
                                onAddressChange={onPickupChange}
                                address={pickupAddress}
                                additionalOptions={locations}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <LocationAutocomplete
                                error={dropoffError}
                                label="Drop-off Location *"
                                onAddressChange={onDropoffChange}
                                address={dropoffAddress}
                                additionalOptions={locations}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                label="Special Notes"
                                fullWidth
                                multiline
                                rows={4}
                                onChange={(e) =>
                                  setShuttleOrder({ ...shuttleOrder, specialNotes: e.target.value })
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                          <MDTypography variant="body2" gutterBottom align="justify">
                            <small>
                              For smooth, efficient and sustainable operations, please provide your
                              transportation needs as timely as possible, i.e. at least one day in
                              advance. Please consider walking and car sharing whenever possible
                              (especially for colleagues staying in the same apartment). Thank you
                              for your cooperation!
                            </small>
                          </MDTypography>
                          <Divider />
                          <MDTypography variant="body2" gutterBottom align="justify">
                            <b>For assistance or changes, contact our Transportation Team:</b>
                            <br />
                            <em>(Preferably via WhatsApp Message)</em>
                            <br />
                            <a href="tel:+41765169011">+41 76 516 90 11 </a> {"// Max"}
                            <br />
                            or <a href="tel:+41765298371">+41 76 529 83 71 </a> {"// Konstantin"}
                            <br />
                            <br />
                            {company.dispoEmail && (
                              <a href={`mailto:${company.dispoEmail}`}>{company.dispoEmail}</a>
                            )}{" "}
                          </MDTypography>
                          <Divider />
                          <FormControlLabel
                            control={
                              <Checkbox required onChange={(e) => setChecked(e.target.checked)} />
                            }
                            label={
                              <span>
                                I have read and accept the{" "}
                                <a href="/privacy" target="_blank" rel="noopener noreferrer">
                                  Privacy Policy
                                </a>
                              </span>
                            }
                          />
                          <MDButton
                            variant="gradient"
                            color="info"
                            style={{ marginTop: "16px", background: "#002985" }}
                            type="submit"
                          >
                            Place Order
                          </MDButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </MDBox>
        </form>
      </ConciergeLayout>
    </LoadScript>
  );
};

export default Shuttle;
