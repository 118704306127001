// React imports
import React, { useEffect, useRef, useState } from "react";

// Material-UI imports
import { Card, CardContent, CardMedia, Checkbox, Divider } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import GetAppIcon from "@mui/icons-material/GetApp";

// API imports
import { AccommodationApi } from "api/src"; // adjust the import according to your project structure
import { TemplateCategoryApi } from "api/src";
import { OrderApi } from "api/src";

// Component imports
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ConciergeLayout from "./components/ConciergeLayout";
import Category from "./components/Category";

// Utility imports
import { lightenColor } from "utils/utils";

// style imports
import "./Concierge.css";

// Asset imports
import bgImage from "assets/images/concierge/davos.jpg";
import mapImage from "assets/images/concierge/map.png";
import headerImage from "assets/images/concierge/header.jpg";
import { Link } from "react-router-dom";

const Concierge = () => {
  const [accommodations, setAccommodations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [order, setOrder] = useState({
    dateOfArrival: "",
    dateOfDeparture: "",
    firstName: "",
    lastName: "",
    specialRequests: "",
  });
  const [accommodationError, setAccommodationError] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const [orderResponse, setOrderResponse] = useState(null);
  const [editOrder, setEditOrder] = useState(false);
  const [loadOrder, setLoadOrder] = useState({
    orderNr: "",
    code: "",
  });
  const [orderLoaded, setOrderLoaded] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [checked, setChecked] = useState(false);

  const accommodationApi = new AccommodationApi();
  const templateCategoryApi = new TemplateCategoryApi();
  const theme = useTheme();

  // Create a ref for the top element
  const topElementRef = useRef(null);

  const specialRequestColor = "#002985";
  const lighterColor = lightenColor(specialRequestColor, 20);
  const specialRequestGradient = `linear-gradient(45deg, ${specialRequestColor} 20%, ${lighterColor} 80%)`;

  const handleSubmit = () => {
    // Add your checks here
    if (
      !order.accommodation ||
      !order.accommodation["@id"] ||
      order.accommodation["@id"] === "-1"
    ) {
      setAccommodationError(true);
      topElementRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }

    // Iterate over the categories
    order.categories.forEach((category) => {
      // Filter out the items that don't meet the conditions
      category.items = category.items.filter((item) => {
        return !(item.quantity <= 1 && item.name === "" && item.kind === "");
      });
    });

    console.log(order);

    const orderApi = new OrderApi();

    if (orderLoaded) {
      // If an order is loaded, update the existing order
      orderApi.apiOrdersIdPut(order, order.id, (error, data, response) => {
        if (error) {
          console.error(error);
        } else {
          setOrderResponse(response.body);
        }
      });
    } else {
      // If no order is loaded, create a new order
      orderApi.apiOrdersPost(order, (error, data, response) => {
        if (error) {
          console.error(error);
        } else {
          setOrderResponse(response.body);
        }
      });
    }
  };

  const handleCategoryChange = (changedCategory, changedProps) => {
    console.log("changedProps", changedProps);
    setOrder((prevOrder) => ({
      ...prevOrder,
      categories: prevOrder.categories.map((category) =>
        category.madeFrom === changedCategory.madeFrom ? { ...category, ...changedProps } : category
      ),
    }));
  };

  useEffect(() => {
    console.log("order changed category", order);
  }, [order]);

  const handleDownload = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;

    // Extract filename from the URL
    const urlObj = new URL(url);
    const filename = urlObj.pathname.split("/").pop();

    link.download = filename;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Fetch the accommodations
  useEffect(() => {
    accommodationApi.apiAccommodationsGetCollection({}, (error, data, response) => {
      if (error) {
        console.error("An error occurred while fetching the accommodations:", error);
      }
      //include a dummy accomodation with @id="-1"
      let accommodations = data["hydra:member"];
      //sort them by nuzmnberOnMap
      accommodations.sort((a, b) => {
        return a.numberOnMap - b.numberOnMap;
      });
      setAccommodations(accommodations);
    });
  }, []);

  // Fetch the template categories
  useEffect(() => {
    templateCategoryApi.apiTemplateCategoriesGetCollection({}, (error, data, response) => {
      if (error) {
        console.error("An error occurred while fetching the template categories:", error);
      } else {
        const categories = data["hydra:member"].map((templateCategory) => {
          const { id, ...rest } = templateCategory;
          return {
            madeFrom: templateCategory["@id"],
            colorHex: rest.colorHex,
            name: rest.name,
            comment: rest.comment || "",
            items: rest.items.map(({ "@id": _, "@type": __, id: ___, ...itemRest }) => itemRest),
          };
        });
        setCategories(categories);
        // Set the order categories
        setOrder((prevOrder) => ({
          ...prevOrder,
          categories: categories,
        }));
      }
    });
  }, []);

  const handleLoadOldOrder = () => {
    // Check if loadOrder is null or empty
    if (!loadOrder || loadOrder.orderNr === "" || loadOrder.code === "") {
      alert("Please provide both order number and code.");
      setOrderError(true);
      return;
    }

    let api_url = `${process.env.REACT_APP_API_URL}`;
    // Make a fetch request to the /orders/orderNr/code endpoint
    fetch(`${api_url}/api/orders/${loadOrder.orderNr}/${loadOrder.code}`)
      .then((response) => {
        if (!response.ok) {
          setOrderError(true);
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        let sortedCategories = data.categories.sort((a, b) => {
          let aIndex = categories.findIndex((cat) => cat.name === a.name);
          let bIndex = categories.findIndex((cat) => cat.name === b.name);
          return aIndex - bIndex;
        });

        setCategories(sortedCategories);
        // Parse and format the dates
        let arrivalDate = new Date(data.dateOfArrival);
        let formattedArrivalDate =
          arrivalDate.getFullYear() +
          "-" +
          String(arrivalDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(arrivalDate.getDate()).padStart(2, "0");

        let departureDate = new Date(data.dateOfDeparture);
        let formattedDepartureDate =
          departureDate.getFullYear() +
          "-" +
          String(departureDate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(departureDate.getDate()).padStart(2, "0");

        // Set the order
        setOrder({
          ...data,
          dateOfArrival: formattedArrivalDate,
          dateOfDeparture: formattedDepartureDate,
          accommodation: data.accommodation ? data.accommodation : null,
        });
        setOrderLoaded(true);
      })
      .catch((error) => {
        console.error("There has been a problem with your fetch operation:", error);
      });
  };

  return (
    <ConciergeLayout image={bgImage}>
      <form
        onSubmit={(e) => {
          e.preventDefault(); // Prevent the default form submission
          handleSubmit(); // Call your submit function
        }}
      >
        <MDBox py={2}>
          <Card sx={{ borderRadius: 1, boxShadow: 5 }} variant="outlined">
            <CardContent p={4}>
              <div ref={topElementRef}>
                <CardMedia
                  component="img"
                  sx={{ width: "100%", marginLeft: 0, marginRight: 0, marginBottom: 4 }}
                  image={headerImage}
                  title="header"
                />
              </div>
              {orderResponse ? (
                <Grid container justifyContent="center" alignItems="center" direction="column">
                  {/*--------------------  Confirmation -----------------------  */}
                  <MDTypography variant="h4" gutterBottom>
                    {orderLoaded ? "Updated Order Confirmation" : "Order Confirmation"}
                  </MDTypography>
                  <MDTypography variant="body1" gutterBottom>
                    Thank you for your order! You can download the order confirmation below.
                  </MDTypography>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    style={{ maxWidth: "500px", marginBottom: "20px" }}
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item xs={6}>
                        <MDTypography variant="body1" fontWeight="bold">
                          Full Name:
                        </MDTypography>
                      </Grid>
                      <Grid item xs={6}>
                        <MDTypography variant="body1">{`${orderResponse.firstName} ${orderResponse.lastName}`}</MDTypography>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={6}>
                        <MDTypography variant="body1" fontWeight="bold">
                          Order Number:
                        </MDTypography>
                      </Grid>
                      <Grid item xs={6}>
                        <MDTypography variant="body1">{orderResponse.orderNumber}</MDTypography>
                      </Grid>
                    </Grid>
                    {/* ... */}
                    <Grid container justifyContent="space-between">
                      <Grid item xs={6}>
                        <MDTypography variant="body1" fontWeight="bold">
                          Accommodation:
                        </MDTypography>
                      </Grid>
                      <Grid item xs={6}>
                        <MDTypography variant="body1">
                          {orderResponse.accommodation.name}, {orderResponse.accommodation.address}
                        </MDTypography>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={6}>
                        <MDTypography variant="body1" fontWeight="bold">
                          Travel Dates:
                        </MDTypography>
                      </Grid>
                      <Grid item xs={6}>
                        <MDTypography variant="body1">
                          {`${new Date(
                            orderResponse.dateOfArrival
                          ).toLocaleDateString()} - ${new Date(
                            orderResponse.dateOfDeparture
                          ).toLocaleDateString()}`}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <MDButton
                    variant="contained"
                    color="success"
                    startIcon={<GetAppIcon />}
                    onClick={() =>
                      handleDownload(
                        `${orderResponse.orderConfirmationPdfUrl}?code=${orderResponse.code}`
                      )
                    }
                  >
                    Download Order Confirmation
                  </MDButton>
                </Grid>
              ) : (
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <img
                      width="100%"
                      style={{ borderRadius: "0.75rem" }}
                      src={mapImage}
                      alt="Map"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={accommodations}
                          sx={{ width: "100%" }}
                          isOptionEqualToValue={(option, value) => option["@id"] === value["@id"]}
                          getOptionLabel={(option) =>
                            `(${option.numberOnMap}) ${option.address} ${option.name}`
                          }
                          renderInput={(params) => (
                            <TextField
                              error={accommodationError}
                              {...params}
                              label="Accommodation *"
                              placeholder="Select an accommodation"
                            />
                          )}
                          renderOption={(props, option, { selected }) => (
                            <Box
                              component="li"
                              sx={{
                                "& > :not(style)": { pl: 2, pr: 2 },
                                ...(selected && { bgcolor: theme.palette.action.selected }),
                              }}
                              {...props}
                            >
                              <span style={{ fontWeight: "bold", width: "50%" }}>
                                ({option.numberOnMap}) {option.address}
                              </span>
                              <span style={{ fontStyle: "italic", width: "50%" }}>
                                {" "}
                                {option.name}
                              </span>
                            </Box>
                          )}
                          value={order.accommodation}
                          onChange={(event, newValue) => {
                            setOrder((prevOrder) => ({
                              ...prevOrder,
                              accommodation: newValue ? newValue : null,
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="First Name"
                              value={order.firstName}
                              onChange={(e) => setOrder({ ...order, firstName: e.target.value })}
                              fullWidth
                              required
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="Last Name"
                              value={order.lastName}
                              onChange={(e) => setOrder({ ...order, lastName: e.target.value })}
                              fullWidth
                              required
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="Date of Arrival"
                              type="date"
                              value={order.dateOfArrival}
                              fullWidth
                              onChange={(e) =>
                                setOrder({ ...order, dateOfArrival: e.target.value })
                              }
                              InputLabelProps={{ shrink: true }}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="Date of Departure"
                              type="date"
                              value={order.dateOfDeparture}
                              fullWidth
                              onChange={(e) =>
                                setOrder({ ...order, dateOfDeparture: e.target.value })
                              }
                              InputLabelProps={{ shrink: true }}
                              required
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <MDTypography variant="body2" gutterBottom align="justify">
                              <small>Fields marked with * are required.</small> <br />
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {showFields ? (
                          <Grid container spacing={2}>
                            {orderLoaded ? (
                              <Grid item xs={12} md={12}>
                                <MDTypography variant="subtitle1">
                                  Order successfully loaded
                                </MDTypography>
                                <MDTypography variant="h6">
                                  Order Number: {loadOrder.orderNr}, Code: {loadOrder.code}
                                </MDTypography>
                              </Grid>
                            ) : (
                              <>
                                {orderError && (
                                  <Grid item xs={12} md={12}>
                                    <MDTypography variant="h6" color="error">
                                      Order Number or Code is incorrect
                                    </MDTypography>
                                  </Grid>
                                )}
                                <Grid item xs={12} sm={4} md={4}>
                                  <TextField
                                    label="Order Number"
                                    error={orderError}
                                    onBlur={(e) =>
                                      setLoadOrder({ ...loadOrder, orderNr: e.target.value })
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                  <TextField
                                    label="Code"
                                    error={orderError}
                                    onBlur={(e) =>
                                      setLoadOrder({ ...loadOrder, code: e.target.value })
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} container justify="flex-end">
                                  <MDButton
                                    onClick={(e) => {
                                      handleLoadOldOrder();
                                    }}
                                    variant="contained"
                                    color="success"
                                  >
                                    Edit
                                  </MDButton>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        ) : (
                          <>
                            <Grid container spacing={2} py={0}>
                              <Grid item xs={12} py={0}>
                                <MDTypography variant="body2" gutterBottom align="justify">
                                  Want to edit an existing order? <br />
                                  <a
                                    href="#"
                                    className="order-link"
                                    onClick={() => setShowFields(!showFields)}
                                  >
                                    Enter your order number and code.
                                  </a>
                                </MDTypography>
                              </Grid>
                            </Grid>
                          </>
                        )}
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Divider />
                            <MDTypography variant="body2" gutterBottom align="justify">
                              <small>
                                At the Annual Meeting 2024, all apartments will be equipped with
                                coffee and tea. Bottled water will be available on request only; tap
                                water in Switzerland is drinking water and has a high quality.
                              </small>
                            </MDTypography>
                            <MDTypography variant="body2" gutterBottom align="justify">
                              <small>
                                Use the form below to order additional food & beverage items for
                                your apartment. Please specify the kind and unit of the items (e.g.
                                loaf of sourdough bread). If possible, consider your needs for the
                                entire duration of your stay to avoid surplus stocks and unnecessary
                                replenishments. Should you unexpectedly run out of stocks, you can
                                approach Tamilla Fischer.
                              </small>
                            </MDTypography>
                            <Divider />
                            <MDTypography variant="body2" gutterBottom align="justify">
                              <b>For assistance, please contact our Concierge Service Tamilla</b>
                              <br /> at
                              <a href="tel:+41765160051"> +41 76 516 00 51</a>{" "}
                              <small>
                                (Back-up: <a href="tel:+4915788519707">+49 157 885 19 707</a>){" "}
                              </small>
                              <br />{" "}
                              <em>(Please provide any request in writing or via WhatsApp only)</em>
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </MDBox>
        {/*--------------------  Special Requests -----------------------  */}
        {!orderResponse ? (
          <>
            {categories.map((category, index) => (
              <Category
                key={index}
                category={category}
                onItemsChange={(newItems) => {
                  handleCategoryChange(category, { items: newItems });
                }}
                onCommentChange={(newComment) => {
                  handleCategoryChange(category, { comment: newComment });
                }}
              />
            ))}
            <MDBox py={2}>
              <Card sx={{ borderRadius: 1, boxShadow: 5 }} variant="outlined">
                <CardContent p={4}>
                  <MDBox
                    my={2}
                    p={2}
                    sx={{
                      background: specialRequestGradient,
                      borderRadius: "0.75rem",
                    }}
                  >
                    <h2 style={{ margin: 0, lineHeight: 1, color: "white" }}>Special Requests:</h2>
                  </MDBox>
                  <TextField
                    value={order.specialRequests}
                    multiline
                    rows={7}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setOrder({ ...order, specialRequests: e.target.value })}
                  />
                  <FormControlLabel
                    control={<Checkbox required onChange={(e) => setChecked(e.target.checked)} />}
                    label={
                      <span>
                        I have read and accept the{" "}
                        <a href="/privacy" target="_blank" rel="noopener noreferrer">
                          Privacy Policy
                        </a>
                      </span>
                    }
                  />
                  <MDButton
                    variant="gradient"
                    color="info"
                    style={{ marginTop: "16px", background: "#002985" }}
                    type="submit"
                  >
                    {orderLoaded ? "Update Order" : "Place order"}
                  </MDButton>
                </CardContent>
              </Card>
            </MDBox>
          </>
        ) : (
          <></>
        )}
      </form>
    </ConciergeLayout>
  );
};
export default Concierge;
