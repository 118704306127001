import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import englishContent from "./english.txt";
import germanContent from "./german.txt";

const Privacy = () => {
  const [content, setContent] = useState("");
  const [language, setLanguage] = useState("english");

  useEffect(() => {
    fetch(language === "english" ? englishContent : germanContent)
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, [language]);

  return (
    <div style={{ margin: "50px 100px" }}>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
        <button
          style={{ marginRight: "10px", padding: "10px", fontSize: "16px" }}
          onClick={() => setLanguage("english")}
        >
          English
        </button>
        <button style={{ padding: "10px", fontSize: "16px" }} onClick={() => setLanguage("german")}>
          German
        </button>
      </div>
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
};

export default Privacy;
