import React, { useCallback, useRef } from "react";
import { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { InfoWindow, useLoadScript } from "@react-google-maps/api";
import { OverlayView } from "@react-google-maps/api";
import { on } from "events";
import PropTypes from "prop-types";

// ...
function extractAddress(data) {
  const description = data.formattedAddress.replace(/<\/?[^>]+(>|$)/g, "");
  const street = `${data.components.route} ${data.components.street_number} `;
  const city = data.components.political;
  const zipCode = data.components.postal_code;
  const country = data.components.country;

  return { description, street, city, zipCode, country };
}

const mapContainerStyle = {
  height: "100%",
  width: "100%",
  minHeight: "400px",
};

const center = { lat: 46.8027, lng: 9.836 };

const ShuttleMapComponent = ({ onPickupChange, onDropoffChange, locations }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [address, setAddress] = useState({});
  const [selectedPickup, setSelectedPickup] = useState(null);
  const [selectedDropOff, setSelectedDropOff] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef();

  useEffect(() => {
    const newMarkers = locations.map((location) => {
      let description = "";

      if (location.street) {
        description += `${location.street}, `;
      }
      if (location.city) {
        description += `${location.city}, `;
      }
      if (location.zipCode) {
        description += `${location.zipCode}, `;
      }
      if (location.country) {
        description += location.country;
      }

      if (description.endsWith(", ")) {
        description = description.slice(0, -2);
      }

      if (location.name) {
        description = `${location.name}, ${description}`;
      }

      let place_id =
        location.place_id || "fl-orders-id-" + Math.random().toString(36).substr(2, 30);

      return {
        position: location.coordinates,
        title: location.name,
        description: description || location.name,
        place_id,
        ...location,
      };
    });

    setMarkers(newMarkers);
  }, [locations]);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onMapClick = useCallback((event) => {
    setSelectedLocation(null);

    const newLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };

    setTimeout(() => setSelectedLocation(newLocation), 0);

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: newLocation }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          const formattedAddress = results[0].formatted_address;
          const addressComponents = results[0].address_components.reduce((acc, component) => {
            const type = component.types[0];
            acc[type] = component.long_name;
            return acc;
          }, {});

          setAddress({
            formattedAddress,
            components: addressComponents,
          });
        }
      }
    });
  }, []);

  const setAsPickup = (selected, isMarker) => {
    // Set this marker as the pickup location
    setSelectedPickup(selected);
    if (isMarker) {
      onPickupChange(selected);
    } else {
      onPickupChange(extractAddress(address));
    }
  };

  const setAsDropOff = (selected, isMarker) => {
    // Set this marker as the drop-off location
    setSelectedDropOff(selected);
    if (isMarker) {
      onDropoffChange(selected);
    } else {
      onDropoffChange(extractAddress(address));
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={12}
      center={center}
      onClick={onMapClick}
      onLoad={onLoad}
    >
      {selectedLocation && (
        <InfoWindow
          position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
          onCloseClick={() => setSelectedLocation(null)}
        >
          <div>
            <h2>{address.name}</h2>
            <p>{address.formattedAddress}</p>
            <a href="#" onClick={() => setAsPickup(selectedLocation)}>
              Set as Pickup
            </a>
            <br />
            <a href="#" onClick={() => setAsDropOff(selectedLocation)}>
              Set as Drop-off
            </a>
          </div>
        </InfoWindow>
      )}
      {markers.map((marker, index) => (
        <Marker
          key={index}
          position={marker.position}
          title={marker.title}
          onClick={() => {
            setSelectedMarker(marker);
          }}
        />
      ))}
      {selectedMarker && (
        <InfoWindow
          position={selectedMarker.position}
          onCloseClick={() => {
            setSelectedMarker(null);
          }}
        >
          <div>
            <h2>{selectedMarker.title}</h2>
            <p>
              {selectedMarker.street}, {selectedMarker.city}, {selectedMarker.zipCode},{" "}
              {selectedMarker.country}
            </p>
            <a href="#" onClick={() => setAsPickup(selectedMarker, true)}>
              Set as Pickup
            </a>
            <br />
            <a href="#" onClick={() => setAsDropOff(selectedMarker, true)}>
              Set as Drop-off
            </a>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

ShuttleMapComponent.propTypes = {
  onPickupChange: PropTypes.func.isRequired,
  onDropoffChange: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
};

export default ShuttleMapComponent;
