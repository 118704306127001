/**
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The CompanyJsonldCompanyRead model module.
* @module model/CompanyJsonldCompanyRead
* @version 1.0.0
*/
export default class CompanyJsonldCompanyRead {
    /**
    * Constructs a new <code>CompanyJsonldCompanyRead</code>.
    * @alias module:model/CompanyJsonldCompanyRead
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>CompanyJsonldCompanyRead</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/CompanyJsonldCompanyRead} obj Optional instance to populate.
    * @return {module:model/CompanyJsonldCompanyRead} The populated <code>CompanyJsonldCompanyRead</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompanyJsonldCompanyRead();
                        
            
            if (data.hasOwnProperty('@context')) {
                obj['@context'] = ApiClient.convertToType(data['@context'], Object);
            }
            if (data.hasOwnProperty('@id')) {
                obj['@id'] = ApiClient.convertToType(data['@id'], Object);
            }
            if (data.hasOwnProperty('@type')) {
                obj['@type'] = ApiClient.convertToType(data['@type'], Object);
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], Object);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], Object);
            }
            if (data.hasOwnProperty('dispoEmail')) {
                obj['dispoEmail'] = ApiClient.convertToType(data['dispoEmail'], Object);
            }
        }
        return obj;
    }

    /**
    * @member {Object} @context
    */
    '@context' = undefined;
    /**
    * @member {Object} @id
    */
    '@id' = undefined;
    /**
    * @member {Object} @type
    */
    '@type' = undefined;
    /**
    * Get the value of code
    * @member {Object} code
    */
    'code' = undefined;
    /**
    * Get the value of name
    * @member {Object} name
    */
    'name' = undefined;
    /**
    * Get the value of dispoEmail
    * @member {Object} dispoEmail
    */
    'dispoEmail' = undefined;




}
