import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear the user's authentication data
    Cookies.remove("token");
    Cookies.remove("isLoggedIn");

    // Redirect the user to the sign-in page
    navigate("/admin/authentication/sign-in");
  }, [navigate]);

  // Render nothing, because this component only exists to perform the logout
  return null;
}

export default Logout;
