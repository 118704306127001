/**
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import CompanyJsonldCompanyRead from '../model/CompanyJsonldCompanyRead';
import CompanyJsonldCompanyWrite from '../model/CompanyJsonldCompanyWrite';
import InlineResponse2003 from '../model/InlineResponse2003';

/**
* Company service.
* @module api/CompanyApi
* @version 1.0.0
*/
export default class CompanyApi {

    /**
    * Constructs a new CompanyApi. 
    * @alias module:api/CompanyApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiCompaniesCodeDelete operation.
     * @callback module:api/CompanyApi~apiCompaniesCodeDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Removes the Company resource.
     * Removes the Company resource.
     * @param {module:api/CompanyApi~apiCompaniesCodeDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiCompaniesCodeDelete(code, callback) {
      let postBody = null;

      let pathParams = {
        'code': code
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/companies/{code}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCompaniesCodeGet operation.
     * @callback module:api/CompanyApi~apiCompaniesCodeGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompanyJsonldCompanyRead} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves a Company resource.
     * Retrieves a Company resource.
     * @param {module:api/CompanyApi~apiCompaniesCodeGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CompanyJsonldCompanyRead}
     */
    apiCompaniesCodeGet(code, callback) {
      let postBody = null;

      let pathParams = {
        'code': code
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/ld+json'];
      let returnType = CompanyJsonldCompanyRead;

      return this.apiClient.callApi(
        '/api/companies/{code}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCompaniesCodePut operation.
     * @callback module:api/CompanyApi~apiCompaniesCodePutCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompanyJsonldCompanyRead} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Replaces the Company resource.
     * Replaces the Company resource.
     * @param {module:api/CompanyApi~apiCompaniesCodePutCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CompanyJsonldCompanyRead}
     */
    apiCompaniesCodePut(body, code, callback) {
      let postBody = body;

      let pathParams = {
        'code': code
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/ld+json'];
      let accepts = ['application/ld+json'];
      let returnType = CompanyJsonldCompanyRead;

      return this.apiClient.callApi(
        '/api/companies/{code}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCompaniesGetCollection operation.
     * @callback module:api/CompanyApi~apiCompaniesGetCollectionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2003} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieves the collection of Company resources.
     * Retrieves the collection of Company resources.
     * @param {Object} opts Optional parameters
     * @param {module:api/CompanyApi~apiCompaniesGetCollectionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2003}
     */
    apiCompaniesGetCollection(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/ld+json'];
      let returnType = InlineResponse2003;

      return this.apiClient.callApi(
        '/api/companies', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiCompaniesPost operation.
     * @callback module:api/CompanyApi~apiCompaniesPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompanyJsonldCompanyRead} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Creates a Company resource.
     * Creates a Company resource.
     * @param {module:api/CompanyApi~apiCompaniesPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CompanyJsonldCompanyRead}
     */
    apiCompaniesPost(body, callback) {
      let postBody = body;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/ld+json'];
      let accepts = ['application/ld+json'];
      let returnType = CompanyJsonldCompanyRead;

      return this.apiClient.callApi(
        '/api/companies', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}
