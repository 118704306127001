/**
 * Hello API Platform
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The InlineResponse2001Hydraview model module.
* @module model/InlineResponse2001Hydraview
* @version 1.0.0
*/
export default class InlineResponse2001Hydraview {
    /**
    * Constructs a new <code>InlineResponse2001Hydraview</code>.
    * @alias module:model/InlineResponse2001Hydraview
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>InlineResponse2001Hydraview</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/InlineResponse2001Hydraview} obj Optional instance to populate.
    * @return {module:model/InlineResponse2001Hydraview} The populated <code>InlineResponse2001Hydraview</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InlineResponse2001Hydraview();
                        
            
            if (data.hasOwnProperty('@id')) {
                obj['@id'] = ApiClient.convertToType(data['@id'], Object);
            }
            if (data.hasOwnProperty('@type')) {
                obj['@type'] = ApiClient.convertToType(data['@type'], Object);
            }
            if (data.hasOwnProperty('hydra:first')) {
                obj['hydra:first'] = ApiClient.convertToType(data['hydra:first'], Object);
            }
            if (data.hasOwnProperty('hydra:last')) {
                obj['hydra:last'] = ApiClient.convertToType(data['hydra:last'], Object);
            }
            if (data.hasOwnProperty('hydra:previous')) {
                obj['hydra:previous'] = ApiClient.convertToType(data['hydra:previous'], Object);
            }
            if (data.hasOwnProperty('hydra:next')) {
                obj['hydra:next'] = ApiClient.convertToType(data['hydra:next'], Object);
            }
        }
        return obj;
    }

    /**
    * @member {Object} @id
    */
    '@id' = undefined;
    /**
    * @member {Object} @type
    */
    '@type' = undefined;
    /**
    * @member {Object} hydra:first
    */
    'hydra:first' = undefined;
    /**
    * @member {Object} hydra:last
    */
    'hydra:last' = undefined;
    /**
    * @member {Object} hydra:previous
    */
    'hydra:previous' = undefined;
    /**
    * @member {Object} hydra:next
    */
    'hydra:next' = undefined;




}
